import React from "react"

import SideLayout from "../components/Sidelayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <SideLayout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </SideLayout>
)

export default NotFoundPage
